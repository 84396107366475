@import "normalize";
@import "slick";
@import "slick-theme";
@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,700|Roboto:300,400&subset=latin-ext');

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin center {
	max-width: 1140px;
	margin: 0 auto;

	html.large-less & {
		padding: 0 20px;
	}
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'PT Serif', serif;
}

body {
	.page {
		position: relative;
		top: 94px;
	}

	&.menu-open {
        height: 100%;

        .page {
            overflow-x: hidden;
            overflow-y: hidden;
            height: 100%;
            //display: none;
        }
    }
}

.container {
	@include center;
}

.row {
    @include clearfix;
}

img {
	max-width: 100%;
}

header {
	position: fixed;
	width: 100%;
	background: #fff;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;

	.container {
		position: relative;
	}

	#logo {
		float: left;

		h1 {
			margin: 16px 0;

			a {
				display: block;
				text-indent: -9999px;
				width: 195px;
				height: 62px;
				background: url(../../img/logo.svg) no-repeat;
				background-size: contain;
			}
		}
	}

	.hamburger {
		position: absolute;
		right: 40px;
		top: 25px;
		z-index: 9999;

		html.x-small-less & {
			right: 30px;
		}
	}

	.hamburger .line{
	  width: 30px;
	  height: 5px;
	  background-color: #898b8f;
	  display: block;
	  margin: 8px auto;
	  -webkit-transition: all 0.3s ease-in-out;
	  -o-transition: all 0.3s ease-in-out;
	  transition: all 0.3s ease-in-out;
	}

	.hamburger:hover{
	  cursor: pointer;
	}

	.hamburger.is-active{
	  -webkit-transition: all 0.3s ease-in-out;
	  -o-transition: all 0.3s ease-in-out;
	  transition: all 0.3s ease-in-out;
	  -webkit-transition-delay: 0.6s;
	  -o-transition-delay: 0.6s;
	  transition-delay: 0.6s;
	  -webkit-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
	  transform: rotate(45deg);
	}

	.hamburger.is-active .line:nth-child(2){
	  width: 0px;
	}

	.hamburger.is-active .line:nth-child(1),
	.hamburger.is-active .line:nth-child(3){
	  -webkit-transition-delay: 0.3s;
	  -o-transition-delay: 0.3s;
	  transition-delay: 0.3s;
	}

	.hamburger.is-active .line:nth-child(1){
	  -webkit-transform: translateY(13px);
	  -ms-transform: translateY(13px);
	  -o-transform: translateY(13px);
	  transform: translateY(13px);
	}

	.hamburger.is-active .line:nth-child(3){
	  -webkit-transform: translateY(-13px) rotate(90deg);
	  -ms-transform: translateY(-13px) rotate(90deg);
	  -o-transform: translateY(-13px) rotate(90deg);
	  transform: translateY(-13px) rotate(90deg);
	}

	#menu {
		float: right;
		//margin-right: 75px;

		html.x-large-less & {
			display: none;
		}

		ul.menu-links {
            list-style: none;
            margin: 0;
            padding: 0;
            display: inline-block;

            li {
                display: inline-block;
                line-height: 94px;
                //margin-left: 50px; Pri spusteni EN verzie treba odkomentovat

                a {
                	font-family: 'Roboto', sans-serif;
                    font-size: 16px;
                    color: #555555;
                    letter-spacing: 0;
                    text-decoration: none;
                    -webkit-font-smoothing: antialiased;
                    display: block;
                    position: relative;
                    padding: 0 25px;

                    &:hover {
                        &:after {
                            content: "";
                            width: 100%;
                            height: 3px;
                            background: #b7a77b;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            opacity: .6;
                        }
                    }

                    &.active {

                        &:after {
                            content: "";
                            width: 100%;
                            height: 3px;
                            background: #b7a77b;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        }
                    }
                }
            }
        }

        #language {
        	display: inline-block;
        	position: absolute;
        	top: 36px;
        	right: 0;
        	display: none; // Ked sa spusti anglicka verzia tak potom zobrazit

        	html.large-less & {
        		right: 20px;
        	}

        	ul {
        		list-style: none;
        		margin: 0;
        		position: relative;
        		padding: 0 25px;
        		cursor: pointer;

        		&:before {
        			content: "";
        			width: 1px;
        			height: 25px;
        			background: #ccc;
        			position: absolute;
        			left: 0;
        			top: 0;
        		}

        		li {

					&:first-child {
						//margin-bottom: 10px;
					}


        			a {
        				color: #bea97a;
        				text-decoration: none;
        				font-family: 'Roboto', sans-serif;
        				-webkit-font-smoothing: antialiased;

        				&.active {
        					display: none;
        				}
        			}
        		}
        	}
        }
	}
}

#layout-content {
	//margin-top: 94px;
}

section#intro {
	background: url(../../img/Background.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;

	&:after {
		content: "";
		width: 100%;
		height: 100px;
		background: rgba(178, 162, 115, 0.14);
		position: absolute;
		left: 0;
		bottom: 130px;
	}

	html.x-small-less & {
		background-size: cover;
	}

	.row {
		position: relative;
	}

	.left {
		width: 50%;
		float: left;
		padding: 160px 0 150px;

		html.x-medium-less & {
			width: 100%;
			float: none;
			padding: 50px 0 150px;
		}

		html.medium-less & {
			padding: 40px 0 150px;
		}

		h2 {
			font-size: 38px;
			line-height: 44px;
			position: relative;

			&:after {
				content: "";
				width: 41px;
				height: 2px;
				background: #898b8f;
				position: absolute;
				left: 0;
				bottom: -15px;
			}
		}

		p {
			font-family: 'Roboto', sans-serif;
			line-height: 32px;
			font-weight: 300;
		}

		.signature {
			margin-top: 130px;
			left: 50px;
			position: relative;
			display: inline-block;

			html.x-medium-less & {
				width: 170px;
				margin: 90px auto 0;
				display: block;
				left: 0;
			}

			.sign {
				width: 196px;
				height: 59px;
				background: url(../../img/podpis-ga.png) no-repeat;
				background-size: contain;
				position: absolute;
				left: -15px;
				top: -57px;

				html.msie & {
					width: 196px;
					height: 59px;
					background: url(../../img/podpis-ga-ie.png) no-repeat;
					background-size: contain;
					position: absolute;
					left: -15px;
					top: -57px;
				}
			}

			p {
				margin: 0;
				line-height: 24px;
			}

			p.name {
				font-family: 'Roboto', sans-serif;
				font-weight: bold;
				font-size: 12px;
				color: #5f5f5f;
				position: relative;
				z-index: 1;
			}

			p.position {
				font-weight: bold;
				color: #898b8f;
				font-size: 12px;
			}

			.stamp {
				width: 94px;
				height: 94px;
				background: url(../../img/peciatka.png) no-repeat;
				background-size: contain;
				position: absolute;
				right: -65px;
				bottom: -40px;
			}
		}
	}

	.right {
		width: 45%;
		float: right;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 1;


		html.large-less & {
			position: absolute;
			right: 0;
			bottom: 0;
		}

		html.x-medium-less & {
			display: none;
		}

		.person-img {
			text-align: right;

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}

}

section#services {
	background: url(../../img/services-bg.png) repeat;
	padding: 145px 0;
	position: relative;

	&:before {
		content: "";
		width: 2px;
		height: 71px;
		background: url(../../img/line-blue-top.png) no-repeat;
		background-size: contain;
		position: absolute;
		left: 50%;
		margin-left: -1px;
		top: -35px;
	}

	html.medium-less & {
		padding: 70px 0 20px;
	}

	.slick-prev, .slick-next {
		top: -80px;
	}

	.container {
		&>h3 {
			margin: 0;
			color: #fff;
			font-size: 32px;
			line-height: 44px;
			text-align: center;
			margin-bottom: 60px;
		}

		.row {
			.col-md-6 {
				width: 50%;
				float: left;
				color: #fff;
				text-align: center;

				&.r1 {
					margin-bottom: 70px;

					html.medium-less & {
						margin-bottom: 40px;
					}

					ul {
						li {
							position: relative;

							&:before {
								content: "";
								width: 10px;
								height: 10px;
								border-radius: 50%;
								background: #4a72c4;
								position: absolute;
								left: 0;
								top: 6px;
							}
						}
					}
				}

				&.r2 {
					ul {
						li {
							position: relative;

							&:before {
								content: "";
								width: 10px;
								height: 10px;
								border-radius: 50%;
								background: #b2a273;
								position: absolute;
								left: 0;
								top: 6px;
							}
						}
					}
				}

				&.c1 {
					.icon {
						width: 80px;
						height: 80px;
						background: url(../../img/service-icon-1.png) no-repeat;
						background-size: contain;
						margin: 0 auto;
					}
				}

				&.c2 {
					.icon {
						width: 93px;
						height: 80px;
						background: url(../../img/service-icon-2.png) no-repeat;
						background-size: contain;
						margin: 0 auto;
					}
				}

				&.c3 {
					.icon {
						width: 88px;
						height: 80px;
						background: url(../../img/service-icon-3.png) no-repeat;
						background-size: contain;
						margin: 0 auto;
					}
				}

				&.c4 {
					.icon {
						width: 104px;
						height: 80px;
						background: url(../../img/service-icon-4.png) no-repeat;
						background-size: contain;
						margin: 0 auto;
					}
				}

				h3 {
					color: #fff;
					text-align: center;
					position: relative;
					font-size: 18px;
					line-height: 26px;

					&:after {
						content: "";
						width: 350px;
						height: 3px;
						background: rgba(255, 255, 255, .1);
						position: absolute;
						left: 50%;
						margin-left: -175px;
						bottom: -20px;
					}
				}

				ul {
					margin: 0;
					padding: 0;
					list-style: none;
					display: inline-block;
					text-align: left;
					margin-top: 20px;

					li {
						font-family: 'Roboto', sans-serif;
						font-weight: 300;
						margin: 20px 0;
						padding-left: 30px;
					}
				}
			}
		}
	}
}

section#our-promise {
	padding-top: 130px;
	position: relative;

	&:before {
		content: "";
		width: 2px;
		height: 71px;
		background: url(../../img/line.png) no-repeat;
		background-size: contain;
		position: absolute;
		left: 50%;
		margin-left: -1px;
		top: -36px;
	}

	html.medium-less & {
		padding: 70px 0 0;
	}

	.top {
		h3 {
			color: #0c0c0c;
			font-size: 32px;
			text-align: center;
			margin: 0 0 420px;
		}
	}

	.bottom {
		background: url(../../img/promise-bg.png) repeat;
		//max-height: 580px;

		.container {
			//max-height: 580px;

			.row {
				position: relative;
				top: -370px;

				html.medium-less & {
					max-height: 700px;
					top: -340px;
				}

				html.x-small-less & {
					max-height: 800px;
				}

				.person {
					width: 60%;
					float: left;
					text-align: center;
					max-height: 600px;
					float: none;
					margin: 0 auto;

					html.medium-less & {
						max-height: 100%;
						width: 90%;
					}

					.person-img {
						img {
							border-bottom: 1px solid rgba(85, 85, 85, .3);
							max-height: 425px;

							html.medium-less & {
								margin: auto;
							}
						}
					}

					p {
						margin: 0;
						padding: 0;
					}

					p.name {
						font-size: 18px;
						color: #0c0c0c;
						font-weight: bold;
						line-height: 23px;
						margin: 40px 0 15px;
					}

					p.position {
						color: #8d8d8d;
						font-size: 14px;
						font-weight: bold;
						line-height: 23px;
						margin-bottom: 20px;
					}

					p.info {
						font-size: 16px;
						line-height: 32px;
						color: #545454;
						font-family: 'Roboto', sans-serif;
						font-weight: 300;
						margin: auto;
						margin-bottom: 30px;
					}

					.contact-info {
						background: #fff;
						display: inline-block;
						padding: 5px 10px;

						a {
							font-family: 'Roboto', sans-serif;
							color: #b2a273;
							font-weight: bold;
							font-size: 14px;
							text-decoration: none;
							display: inline-block;
							padding: 0 5px;

							&.phone {
								border-right: 1px solid #b2a273;
								padding-right: 7px;
							}
						}
					}
				}
			}
		}
	}
}

section#contact {
	background: url(../../img/promise-bg.png) repeat;

	.row {
		position: relative;
	}

	.form {
		width: 50%;
		float: left;
		padding: 100px 0;

		html.large-less & {
			width: 100%;
			float: none;
		}

		html.medium-less & {
			padding: 70px 0 80px;
		}

		.form-wrap {
			width: 570px;
			float: right;
			font-family: 'Roboto', sans-serif;
			font-weight: 300;
			padding-right: 50px;
			box-sizing: border-box;

			html.large-less & {
				width: 100%;
				float: none;
				padding: 0 100px;
			}

			html.medium-less & {
				padding: 0 20px;
			}

			h3 {
				font-size: 32px;
				line-height: 44px;
				font-family: 'PT Serif', serif;
				margin-top: 0;
			}

			form {
				.row {
					margin-left: -10px;

					html.medium-less & {
						margin-left: 0;
					}
				}

				.col-md-4 {
					width: 33.333%;
					float: left;
					padding: 10px;
					box-sizing: border-box;

					html.medium-less & {
						width: 100%;
						float: none;
					}

					input {
						border: none;
						border-bottom: 1px solid #d7dadf;
						padding: 15px 10px;
						box-sizing: border-box;
						font-size: 14px;
						width: 100%;
						outline: none;

						&:focus {
							border-bottom: 1px solid #b2a273;
						}

						&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		                    color: rgba(0, 0, 0, .5);
		                }
					}
				}

				.col-md-12 {
					padding-right: 10px;
					margin-top: 10px;

					html.medium-less & {
						padding: 10px;
						padding-top: 0;
					}
				}

				textarea {
					border: none;
					border-bottom: 1px solid #d7dadf;
					padding: 20px 10px;
					box-sizing: border-box;
					font-size: 14px;
					width: 100%;
					outline: none;
					position: relative;
					z-index: 99;

					&:focus {
						border-bottom: 1px solid #b2a273;
					}
				}

				.field-wrapper {
					html.medium-less & {
						text-align: center;
					}
				}

				button {
					background: #b2a273;
					color: #fff;
					font-size: 14px;
					font-weight: bold;
					border: none;
					padding: 10px 50px;
					text-transform: uppercase;
					border-radius: 20px;
					margin-top: 30px;
					outline: none;

					html.medium-less & {
						margin-top: 6px;
					}
				}

				#emptyForm_forms_flash {
					padding-right: 10px;

					.alert {
						padding: 20px;
						background: #f9f7f7;
					    border: 3px solid #ff5858;

					    &.alert-success {
					    	border: 3px solid #bada55;
					    }

						button {
							display: none;
						}

						h4 {
							margin: 0;
						}

						ul {
							margin: 0;
							padding: 0;
							margin-top: 10px;

							li {
								display: block;
								margin-right: 10px;
							}
						}
					}
				}
			}
		}
	}

	#map {
		position: absolute;
        //padding-bottom: 42%; // This is the aspect ratio
        //height: 533px;
        //height: 623px;
        height: 100%;
        overflow: hidden;
        width: 50%;
        float: right;
        right: 0;

        html.large-less & {
        	position: relative;
        	width: 100%;
        	float: none;
        	height: 500px;
        }

        html.medium-less & {
        	height: 300px;
        }

        #map-wrap {
        	height: 100%;
        	width: 100%;

        	.map-popup {
        		text-align: center;
        		margin-left: 25px;

        		#bodyContent {
        			a {
	    				color: #b2a273;
						font-size: 12px;
						font-family: 'Roboto', sans-serif;
						font-weight: bold;
						text-transform: uppercase;
						text-decoration: none;
						display: inline-block;
						background: #fcfcfc;
						padding: 10px 15px;
						border: 1px solid #b2a273;
						border-radius: 20px;
						transition: all 0.1s ease;

						&:hover {
							background: #b2a273;
    						color: #fff;
						}
        			}
        		}
        	}
        }

        iframe {
        	position: absolute;
	        top: 0;
	        left: 0;
	        width: 100% !important;
	        height: 100% !important;
        }

        .overlay {
        	position: absolute;
        	left: 0;
        	top: 0;
        	width: 100%;
        	height: 100%;
        	z-index: 1;
        	cursor: pointer;
        }
	}
}

#layout-footer {
	background: #1c2229;
	color: #989898;
	padding: 70px 0;
	font-family: 'Roboto', sans-serif;

	html.medium-less & {
		padding-top: 80px;
	}

	.container {
			h3 {
				font-family: 'PT Serif', serif;
				margin: 0;
				padding: 0;
				color: #fff;
				font-size: 18px;
				font-weight: normal;
			}

		.col-md-5 {
			width: 36%;
			float: left;
			padding-top: 30px;
			position: relative;
			padding-right: 60px;
			box-sizing: border-box;

			html.x-large-less & {
				width: 100%;
				float: none;
				margin-bottom: 30px;
			}

			html.medium-less & {
				text-align: center;
				padding-right: 0;
			}

			&:before {
				content: "";
				width: 120px;
				height: 2px;
				background: #b2a273;
				position: absolute;
				left: 0;
				top: 0;

				html.medium-less & {
					left: 50%;
					margin-left: -60px;
				}
			}

			.footer-logo {
				width: 241px;
				height: 95px;
				background: url(../../img/footer-logo.png) no-repeat;
				background-size: contain;

				html.medium-less & {
					margin: auto;
				}
			}

			p {
				font-size: 12px;
				line-height: 32px;
			}
		}

		.col-md-2 {
			width: 24%;
			float: left;
			text-align: left;

			html.x-large-less & {
				width: 33.333%;
			}

			html.medium-less & {
				width: 50%;
				margin-bottom: 30px;
				text-align: center;
			}

			html.small-less & {
				width: 100%;
				float: none;
			}

			h3 {
				margin: 0;
				padding: 0;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				margin-top: 20px;

				li {
					font-size: 12px;
					line-height: 25px;
					margin: 3px 0;
					text-align: left;

					html.medium-less & {
						text-align: center;
					}

					a {
						color: #989898;
						text-decoration: none;
					}
				}
			}
		}

		.col-md-3 {
			width: 16%;
			float: left;

			html.x-large-less & {
				width: 33.333%;
			}

			html.medium-less & {
				width: 100%;
				float: none;
				text-align: center;
			}

			h3 {
				margin: 0;
				padding: 0;
			}

			.sak-logo {
				a {
					img {
						display: block;
						margin: 30px 0 77px;

						html.medium-less & {
							margin: 30px auto 77px;
						}
					}
				}
			}

			p {
				font-size: 10px;

				img {
					width: 83px;
				}
			}
		}
	}
}

.mobile-menu {
	position: fixed;
    top: 93px;
    right: 0;
    background-color: #fff;
    width: 100%;
    height: calc(100% - 93px);
    z-index: 999;
    line-height: 1.5;
    //display: none;
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -moz-transition: -moz-transform 0.4s;
    -o-transition: -o-transform 0.4s;
    -webkit-transition: -webkit-transform 0.4s;
    transition: transform 0.4s;

    &.open {
    	-moz-transform: translateX(0);
	    -ms-transform: translateX(0);
	    -webkit-transform: translateX(0);
	    transform: translateX(0);
    }

    .scroll {
    	overflow: scroll;
    	height: 100%;
    }

    #menu {
    	ul {
    		list-style: none;
    		margin: 0;
    		padding: 0;
    		text-align: center;
    		margin-top: 30px;

    		li {
    			margin: 15px 0;
    			opacity: 1;
				transition: opacity 0.5s ease;

				&.hide {
					opacity: 0;
				}

    			a {
    				font-family: 'Roboto', sans-serif;
    				color: #555555;
    				font-size: 24px;
    				text-decoration: none;
    			}
    		}
    	}

    	#language {
    		opacity: 1;
			transition: opacity 0.5s ease;
			display: none; // Zobrazit pri spusteni EN verzie

			&.hide {
				opacity: 0;
			}
    	}
    }
}

.slick-prev, .slick-next {
	top: -97px;
	height: 32px;
    width: 30px;
    z-index: 99;

    &:before {
    	font-size: 30px;
    }
}

.slick-prev {
	left: 0;
}

.slick-next {
	right: 0;
}

#our-promise {
	.slick-prev, .slick-next {

	    &:before {
	    	color: #000;
	    }
	}
}

section#references {
	padding: 130px 0;

	html.medium-less & {
		padding: 70px 0 100px;
	}

	h3 {
		color: #0c0c0c;
		font-size: 32px;
		text-align: center;
		margin: 0 0 100px;
	}

	.references {
		position: relative;

		&:after {
			content: "";
			width: 60%;
			height: 1px;
			background: #e6e6e6;
			position: absolute;
			bottom: 0;
			left: 50%;
			margin-left: -30%;
		}

		.reference {
			width: 33.333%;
			float: left;
			text-align: center;
			padding: 0 20px;
			box-sizing: border-box;
			padding-bottom: 50px;
			outline: none;

			&:hover {
				.image {
					&:after {
						background: #b5a578;
					}
				}

				.text {
					background: #f7f7f7;
					border-radius: 3px;
					-webkit-box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.19);
					-moz-box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.19);
					box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.19);
					position: relative;

					&:before {
						content: "";
						width: 0; 
						height: 0; 
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-bottom: 10px solid #f7f7f7;
						position: absolute;
						left: 50%;
						margin-left: -10px;
						top: -10px;
					}
				}
			}

			.image {
				position: relative;
				margin: 0 auto 70px;
				height: 70px;
				display : flex;
  				align-items : center;
  				max-width: 86%;

				&:after {
					content: "";
					width: 36px;
					height: 2px;
					background: #e6e6e6;
					position: absolute;
					left: 50%;
					margin-left: -18px;
					bottom: -40px;
				}
			}

			.title {
				font-size: 14px;
				color: #0c0c0c;
				margin-bottom: 30px;
			}

			img {
				margin: auto;
				height: auto;
				max-height: 70px;
				width: auto;
				max-width: 100%;
			}

			.text {
				font-family: 'Roboto', sans-serif;
				text-align: left;
				font-weight: 300;
				padding: 30px;
				color: #545454;
				line-height: 32px;

				span {
					font-weight: normal;
					color: #2d2d2d;
				}
			}
		}

		.slick-dots {
			bottom: -60px;

			li {
				&.slick-active {
					button {
						&:before {
							color: #b5a578;
							opacity: 1;
						}	
					}
				}

				button {
					&:hover {
						opacity: .5;
					}

					&:before {
						font-size: 10px;
					}
				}
			}
		}
	}
}

section#news {
	background: #222d3a;
	padding: 100px 0;
	text-align: center;
	position: relative;

	.slick-dots {
		bottom: -40px;

		li {
			&.slick-active {
				button {
					&:before {
						color: #b5a578;
						opacity: 1;
					}	
				}
			}

			button {
				&:hover {
					opacity: .5;
				}

				&:before {
					font-size: 10px;
					color: rgba(255, 255, 255, .6);
				}
			}
		}
	}

	&:before {
		content: "";
		width: 2px;
		height: 71px;
		background: url(../../img/line-blue-top.png) no-repeat;
		background-size: contain;
		position: absolute;
		left: 50%;
		margin-left: -1px;
		top: -35px;
	}

	html.medium-less & {
		padding: 70px 0 80px;
	}

	.container {
		text-align: left;

		h3 {
			color: #fff;
			font-size: 32px;
			text-align: center;
			margin: 0 0 100px;
		}

		.row {
			margin-bottom: 60px;
		}

		article {
			width: 31.5%;
    		margin-right: 2.75%;
			float: left;
			background: #f7f7f7;
			border-radius: 3px;
			border-bottom: 3px solid #b2a273;
			position: relative;

			&:hover {
				background: #fff;

				.text-wrapper {
					a {
						background: #b2a273;
						color: #fff;
					}
				}
			}

			html.x-large-less & {
				width: 50%;
				margin: 0 1%;
			}

			&.category-1 {
				position: relative;

				&:before {
					content: "";
					width: calc(100% - 40px);
					height: calc(100% - 40px);
					border: 1px solid #acacac;
					border-radius: 3px;
					position: absolute;
					left: 20px;
					top: 20px;
				}

				.image-wrapper {
					display: none;
				}

				.categories-date-wrapper {
					display: block;
					position: absolute;
					left: 45px;
					top: 45px;

					.categories-wrapper {
						display: inline-block;
					}

					.date-published {
						display: inline-block;
						color: #8f8f8f;
						font-size: 14px;
						font-weight: bold;
					}

					.category {
						display: none;

						&.category-1 {
							display: inline-block;
							position: relative;
							font-size: 14px;
							font-weight: bold;
							color: #c69839;
							padding-left: 25px;

							&:before {
								content: "";
								width: 17px;
								height: 30px;
								background: url(../../img/rychle-novinky.png) no-repeat;
								background-size: contain;
								position: absolute;
								left: 0;
								top: 50%;
								margin-top: -15px;
							}
						}
					}
				}

				.text-wrapper {
					margin: 20px;
					//border: 1px solid #acacac;
					//border-radius: 3px;
					padding-top: 60px;
					padding-bottom: 15px;

					p {
						height: 317px;
						overflow: visible;
						overflow: hidden;
						display: block;
						display: -webkit-box;
						-webkit-line-clamp: 10;
	  					-webkit-box-orient: vertical;
	  					text-overflow: ellipsis;
	  					margin-bottom: 0;

						html.large-less & {
							height: auto;
						}
					}

					a {
						opacity: 0;
						height: 0;
						padding: 0;
					}
				}
			}

			&:nth-child(3n) {
				margin-right: 0;
			}

			.image-wrapper {
				max-height: 220px;
				overflow: hidden;

				img {
					max-width: 100%;
					border-top-left-radius: 3px;
					border-top-right-radius: 3px;

					html.medium-less & {
						width: 100%;
					}
				}
			}

			.categories-date-wrapper {
				display: none;
			}

			h3 {
				color: #0c0c0c;
				font-size: 18px;
				line-height: 23px;
				text-align: left;
				margin: 0;
				margin-bottom: 20px;
			}

			.text-wrapper {
				padding: 25px;

				p {
					color: #545454;
					font-family: 'Roboto', sans-serif;
					font-weight: 300;
					line-height: 32px;
					height: 128px;
					overflow: hidden;
					display: block;
					display: -webkit-box;
					-webkit-line-clamp: 4;
  					-webkit-box-orient: vertical;
  					text-overflow: ellipsis;
				}

				a {
					color: #b2a273;
					font-size: 14px;
					font-family: 'Roboto', sans-serif;
					font-weight: bold;
					text-transform: uppercase;
					text-decoration: none;
					display: inline-block;
					background: #fcfcfc;
					padding: 10px 30px;
					border: 1px solid #b2a273;
					border-radius: 20px;
					transition: all 0.1s ease;

					&:after {
						content: "";
						width: 100%;
						height: 100%;
						position: absolute;
						left: 0;
						top: 0;
						z-index: 1;
					}
				}
			}
		}
	}

	a.news-hp-all {
		color: #b2a273;
		font-size: 14px;
		font-family: 'Roboto', sans-serif;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none;
		display: inline-block;
		background: #fcfcfc;
		padding: 10px 30px;
		border: 1px solid #b2a273;
		border-radius: 20px;
		transition: all 0.1s ease;

		&:hover {
			background: #b2a273;
			color: #fff;
		}
	}
}

.blog-posts {
	section#news {
		background: #fff;

		&:before {
			display: none;
		}

		article {
			margin-bottom: 2.75%;
			-webkit-box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.19);
			-moz-box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.19);
			box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.19);

			html.x-large-less & {
				width: 48%;
				margin: 1%;
			}

			html.medium-less & {
				width: 100%;
				margin: 2% 0;

				.image-wrapper {
					max-height: 100%;

					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}

		.pagination {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				border: 1px solid #b2a273;
				font-family: 'Roboto', sans-serif;
				font-size: 14px;
				margin: 0 3px;

				&:hover {
					background: #b2a273;

					a {
						color: #fff;
					}
				}

				span {
					padding: 3px 8px;
					display: block;
				}

				a {
					color: #b2a273;
					font-weight: bold;
					text-decoration: none;
					display: block;
					padding: 3px 8px;
				}
			}
		}
	}
	
	.posts-header {
		background-color: #ece8dc;
		padding: 100px 0 200px;
		background-image: url(../../img/symbol@2x.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: right;

		html.medium-less & {
			padding: 50px 0;
			background-size: cover;
		}

		.news-header {
			@include center;
			padding-left: 190px;

			h2 {
				font-size: 38px;
				line-height: 44px;
				position: relative;

				&:after {
					content: "";
					width: 41px;
					height: 2px;
					background: #898b8f;
					position: absolute;
					left: 0;
					bottom: -20px;
				}
			}

			.text {
				font-family: 'Roboto', sans-serif;
				font-weight: 300;
				line-height: 32px;
				padding-right: 40%;

				html.medium-less & {
					padding-right: 0;
				}
			}
		}
	}

	.categories {
		position: relative;
		top: -130px;
		@include center;

		html.medium-less & {
			top: 0;
			margin-top: 50px;
		}

		ul {
			margin: 0;
		}

		.categories-name {
			display: inline-block;
			color: #9c8a63;
			font-family: 'Roboto', sans-serif;

			html.x-medium-less & {
				display: block;
			}
		}

		.categories2 {
			display: inline-block;
			border-left: 1px solid #9c8a63;
			padding-left: 10px;
			margin-left: 6px;

			html.x-medium-less & {
				border-left: none;
				padding-left: 0;
				margin-left: 0;
				display: inline;
			}
		}

		.category-list {
			list-style: none;
			padding: 0;
			display: inline-block;

			html.x-medium-less & {
				display: inline;
			}

			li {
				display: inline-block;

				html.x-medium-less & {
					margin: 10px 0;
				}

				&.active {
					a {
						color: #fff;
						font-weight: bold;
						background: #b2a273;
						border: 1px solid #b2a273;
					}
				}
				
				a {
					font-family: 'Roboto', sans-serif;
					text-decoration: none;
					display: inline-block;
					color: #808080;
					background: #fff;
					padding: 3px 8px;
					border: 1px solid #808080;
					border-radius: 10px;
					font-size: 13px;
				}

				span {
					display: none;
				}
			}
		}
	}

	.posts {
		position: relative;
		top: -80px;

		html.medium-less & {
			top: 0;
			margin-top: 50px;
		}

		#news {
			background: transparent;
			padding: 0;
		}
	}
}

section.news-post {
	.image-wrapper {
		text-align: center;
		position: relative;

		&:after {
			content: "";
			width: 100%;
			height: 380px;
			background: url(../../img/novinky-img-bg.png) repeat;
			position: absolute;
			left: 0;
			bottom: -200px;
			z-index: -1;
		}

		.img-wrap {
			max-height: 490px;
			overflow: hidden;

			img {
				html.medium-less & {
					max-height: 400px;
				}
			}
		}
	}

	article {
		@include center;
		max-width: 860px;
		background: #fff;
		padding: 50px 130px;
		box-sizing: border-box;
		position: relative;
		top: -160px;
		-webkit-box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.19);
		-moz-box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.19);
		box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.19);
		position: relative;
		text-align: center;

		html.large-less & {
			padding: 50px 130px;
		}

		html.medium-less & {
			padding: 50px;
			top: 0;
		}

		&:after {
			content: "";
			width: 100%;
			height: 33px;
			background: #fff url(../../img/news-bottom-bg.png) repeat-x;
			background-position: center;
			position: absolute;
			bottom: -33px;
			left: 0;
		}

		&.no-image {
			top: 0;
			margin: 90px auto 100px;
			border-top: 1px solid #eee;
			-webkit-box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.50);
			-moz-box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.50);
			box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.50);

			&:after {
				
			}
		}

		h1 {
			margin: 0;
			color: #131313;
			font-size: 38px;
			line-height: 44px;
			text-align: center;
			margin-bottom: 30px;
			
			html.medium-less & {
				padding-top: 20px;
			}
		}

		.author-date-wrapper {
			font-family: 'Roboto', sans-serif;
			font-size: 14px;
			color: #131313;
			font-weight: 300;
			background: #f0ece3;
			border-radius: 20px;
			display: inline-block;
			padding: 5px 15px;
			margin-bottom: 50px;

			.author {
				display: inline-block;
				border-right: 1px solid #131313;
				padding-right: 12px;
				margin-right: 10px;
			}

			.date-published {
				display: inline-block;
			}
		}

		.news-text {
			text-align: left;
			padding-bottom: 50px;
			font-family: 'Roboto', sans-serif;
			font-weight: 300;
			color: #555555;

			h1 {
				text-align: left;
				color: #555555;
			}

			p {
				//padding: 0 140px;
				font-family: 'Roboto', sans-serif;
				font-weight: 300;
				line-height: 1.4;
				color: #555555;
			}

			ul, ol {
				line-height: 1.4;

				li {
					margin-bottom: 8px;
				}
			}

			blockquote {
				background: #9c8757;
				margin: 0;
				padding: 50px 80px;
				margin: 60px 0;

				p {
					margin: 0;
					color: #fff;
					font-family: 'PT Serif', serif;
					font-size: 18px;
					line-height: 27px;
					letter-spacing: 1px;
					-webkit-font-smoothing: antialiased;
					position: relative;

					&:before {
						content: "";
						width: 30px;
						height: 22px;
						background: url(../../img/quote.png) no-repeat;
						background-size: contain;
						position: absolute;
						left: -50px;
						top: 0;
					}
				}
			}

			img {
				max-width: 100%;
			}
		}
	}
}


